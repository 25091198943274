import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import {
  BodyText,
  ContentPadding,
  CreamBackground,
  Heading,
  ImageContainer,
  SplitContainer,
  SplitItem,
  StaticContainer,
  StyledImage,
  TopMagin,
} from '../styles/static.js'

const WhyPage = ({ data }) => {
  const stack = data.Comfort.childImageSharp.gatsbyImageData
  return (
    <Layout>
      <SEO
        title={'Our Store'}
        description={
          'Come past to experience our luggage where we can help you with packing tips and travel experience advice along the way.'
        }
      />
      <StaticContainer>
        <TopMagin />
        <CreamBackground>
          <ContentPadding>
            <SplitContainer center={true}>
              <SplitItem>
                <ImageContainer>
                  <StyledImage image={stack} />
                </ImageContainer>
              </SplitItem>
              <SplitItem>
                <Heading main={true}>
                  {' '}
                  Experience July at <br /> Emporium Melbourne.{' '}
                </Heading>
                <br />
                <BodyText>
                  <p>
                    {' '}
                    Come past to experience our luggage where we can help you
                    with packing tips and travel experience advice along the
                    way.{' '}
                  </p>
                  <p>
                    {' '}
                    We can't wait to meet you. <br />
                    <br />{' '}
                  </p>
                  <p>
                    {' '}
                    Ground Floor, Shop G048 <br /> 287 Lonsdale St, Melbourne
                    VIC 3000{' '}
                  </p>
                  <p>
                    {' '}
                    <a
                      style={{
                        color: '#475F4C',
                        textDecoration: 'underline',
                      }}
                      href="tel:0380019009"
                    >
                      03 8001 9009
                    </a>{' '}
                  </p>
                  <p>
                    {' '}
                    Open 7 days <br /> Saturday to Wednesday 10am–7pm <br />{' '}
                    Thursday & Friday 10am - 9pm.{' '}
                  </p>
                  <p> </p>
                  <p>
                    {' '}
                    <a
                      style={{
                        color: '#475F4C',
                        textDecoration: 'underline',
                      }}
                      href="http://j.mp/emporiumstoredirections "
                    >
                      Get directions here.
                    </a>{' '}
                  </p>
                </BodyText>
              </SplitItem>
            </SplitContainer>
          </ContentPadding>
        </CreamBackground>
      </StaticContainer>
    </Layout>
  )
}

export default WhyPage

export const query = graphql`
  {
    Comfort: file(relativePath: { eq: "store_02-small.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
`
